// @ts-check
import ReCAPTCHA from "react-google-recaptcha";

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Row, Col, Container, Label, Button } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import isEmail from 'validator/lib/isEmail';

import illustration1 from '../../assets/images/logo-login.png';
import illustrationPurple from '../../assets/images/logo_new.png';
import GetCommonStyle from '../../controls/common/getCommonStyle';

import { useSsoAuth } from '../../hooks/useSsoAuth';
import { isMvpTestUser, login, signOutUser, clearMessage } from './authActions';

export default function Login(props) {
  const isDszDomain = window.location.host.indexOf('dropshipzone.com.au') >= 0
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const DszUrl = process.env.REACT_APP_DSZ_WEBSITE_URL

  const { showFeatureItems5 } = useSelector((state) => state.ThemeOptions);

  const dispatch = useDispatch();
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const sessionExpired = params.sessionExpired || params.session_expired;
  const isDisplayRecaptcha = params.isDisplayRecaptcha ?? 'true'
  const isNeedRecaptchaCheck = (isDisplayRecaptcha == 'true' && isDszDomain) ? true : false;

  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [ssoMessage, setSsoMessage] = useState('');
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [recaptchaMessage, setrecaptchaMessage] = useState('');
  const captchaRef = useRef(null)
  const { message, dszEmail, token, authCode } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { ssoEnabled, ssoReady, ssoLoggedIn, ssoLogin, ssoLogout, resetSsoLoggedIn } = useSsoAuth(
    dszEmail,
    token,
    email
  );
  const { returnUrl, return_url } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const theReturnUrl = returnUrl || return_url
  if(theReturnUrl){
    sessionStorage.setItem('returnUrl', theReturnUrl)
  }
  const [isIncorrectEmail, setIsIncorrectEmail] = useState(false);
  const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);
  const [isInVaildEmail, setIsInVaildEmail] = useState(false);

  const onChangeUsername = (e) => {
    setIsInVaildEmail(false)
    setIsIncorrectEmail(false)
    const email = e.target.value;
    setUsername(email);
  };

  const onChangePassword = (e) => {
    setIsIncorrectPassword(false)
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = async (e) => {
    setIsInVaildEmail(false)
    setIsIncorrectEmail(false)
    setIsIncorrectPassword(false)
    dispatch(clearMessage())
    setLoading(true);

    if (!isEmail(email)) {
      setIsInVaildEmail(true)
      setLoading(false);
      return;
    }
    let isVerifyRecaptcha
    let recaptchaResponse
    if (isNeedRecaptchaCheck) {
      if (!isRecaptchaChecked) {
        setrecaptchaMessage('This is a required field')
        setLoading(false);
        return;
      }
      recaptchaResponse = captchaRef.current.getValue();
      isVerifyRecaptcha = true
    }
    const loginRe = await dispatch(login(email, password, ssoEnabled, isVerifyRecaptcha, recaptchaResponse, theReturnUrl));
    setIsRecaptchaChecked(false);

    console.log(
      `handleLogin: ` +
      JSON.stringify({ dszEmail, token, currentUserProfile, ssoLoggedIn })
    );
    if (loginRe.status) {
      if (ssoEnabled) {
        setSsoMessage('')
        await resetSsoLoggedIn()
        console.log(`need to do sso login`);
      } else {
        console.log(`no need to do sso login`)
        sessionStorage.removeItem('returnUrl')
        if ( theReturnUrl && theReturnUrl.indexOf("/customer/account/afterLogin") < 0 ) {
          const url = new URL(theReturnUrl);
          url.searchParams.append('auth_code', loginRe.data.auth_code)
          window.location.href = url.href
        }else{
          if ((showFeatureItems5 && isMvpTestUser(email)) || (isDszDomain && isMvpTestUser(email))) {
            props.history.push('/orders');
          } else {
            props.history.push('/MyAccount');
          }
        }
      }
    } else {
      if (loginRe.data.indexOf('no customer') >= 0) {
        setIsIncorrectEmail(true)
        setLoading(false);
      }
      if (loginRe.data.indexOf('invalid user or password') >= 0) {
        setIsIncorrectPassword(true)
        setLoading(false);
      }
      if (isNeedRecaptchaCheck) {
        captchaRef.current.reset();
      }
    }
  };

  function onChange(response) {
    if (response) {
      setIsRecaptchaChecked(true);
      setrecaptchaMessage('')
      return true;
    } else {
      setIsRecaptchaChecked(false);
      return false;
    }
  }

  const errorMsg = message || ssoMessage;
  console.log(`ssoEnabled: ${ssoEnabled}, ssoReady: ${ssoReady}, dszEmail: ${dszEmail}, token: ${token}, currentUserProfile: ${JSON.stringify(currentUserProfile)}, sessionExpired: ${sessionExpired}, errorMsg: ${errorMsg}`);

  useEffect(() => {
    const handleSsoLogin = async () => {
      console.log(ssoEnabled, ssoReady, currentUserProfile, !ssoLoggedIn);
      if (ssoEnabled && ssoReady && currentUserProfile && !ssoLoggedIn) {
        console.log(
          `handleSsoLogin: ` +
          JSON.stringify({ dszEmail, token, currentUserProfile, ssoLoggedIn })
        );
        const ssoLoginResult = await ssoLogin();
        if (ssoLoginResult) {
          setSsoMessage('')
          sessionStorage.removeItem('returnUrl')
          if ( theReturnUrl && theReturnUrl.indexOf("/customer/account/afterLogin") < 0 ) {
            const url = new URL(theReturnUrl);
            url.searchParams.append('auth_code', authCode)
            window.location.href = url.href
          } else {
            if ((showFeatureItems5 && isMvpTestUser(email)) || (isDszDomain && isMvpTestUser(email))) {
              props.history.push('/orders');
            } else {
              props.history.push('/MyAccount');
            }
          }
        } else {
          if (isNeedRecaptchaCheck) {
            captchaRef.current.reset();
          }
          setSsoMessage('sso login failed, please retry later');
          await dispatch(signOutUser());
        }
      }
    };

    if (errorMsg || recaptchaMessage) {
      setLoading(false);
    } else {
      handleSsoLogin();
    }

    const handleSessionExpired = async () => {
      if (dszEmail) {
        await dispatch(signOutUser());
        if (ssoEnabled && ssoReady) {
          await ssoLogout();
        }
      }
      let loginUrl = '/login'
      if ( theReturnUrl ) {
        loginUrl += `?return_url=${theReturnUrl}`
      }
      console.log('url', loginUrl);  
      props.history.push(loginUrl);
    };

    if (sessionExpired) {
      handleSessionExpired();
    }
  }, [
    ssoEnabled,
    ssoReady,
    dszEmail,
    token,
    currentUserProfile,
    ssoLoggedIn,
    sessionExpired,
    errorMsg
  ]);

  let forceRefresh = localStorage.getItem('forceRefresh');
  if (forceRefresh == 'true' && window.location.host.indexOf('sofortig.com.au') >= 0) {
    window.location.reload()
    localStorage.removeItem('forceRefresh')
  }

  return (
    <>
      <GetCommonStyle />
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container>
                    <Row>
                      <Col
                        xs={{ order: 2 }}
                        md={{ order: 1 }}
                        lg="6"
                        className="d-flex align-items-center">
                        <div className="divider-v d-none d-lg-block divider-v-md" />
                        <AvForm onValidSubmit={handleLogin} className="w-100">
                          <div className="w-100 pr-0 pr-lg-5">
                            <div className="text-black mt-3">
                              <span className="text-center">
                                <h1 className="display-4 mb-1 font-weight-bold">
                                  Login to your account
                                </h1>
                                <p></p>
                              </span>

                              <div className="w-100">
                                <AvGroup>
                                  <Label for="email">
                                    Email<span className="text-danger">*</span>
                                  </Label>
                                  <AvInput
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={onChangeUsername}
                                    required
                                    style={{ borderColor: isIncorrectEmail || isInVaildEmail ? 'red' : '' }}
                                  />
                                  {isIncorrectEmail && <div className="text-danger" style={{ marginTop: '8px' }}>Incorrect email</div>}
                                  {isInVaildEmail && <div className="text-danger" style={{ marginTop: '8px' }}>Enter a valid email</div>}
                                  {/* <AvFeedback>
                                    Please enter your email address!
                                  </AvFeedback> */}
                                </AvGroup>
                                <AvGroup>
                                  <div className="form-group mb-4">
                                    <div className="d-flex justify-content-between">
                                      <Label for="password">
                                        Password<span className="text-danger">*</span>
                                      </Label>
                                      {/* <a href="/recover">Forgot password?</a> */}
                                    </div>
                                    <AvInput
                                      type="password"
                                      name="password"
                                      value={password}
                                      onChange={onChangePassword}
                                      required
                                      style={{ borderColor: isIncorrectPassword ? 'red' : '' }}
                                    />
                                    {isIncorrectPassword && <div className="text-danger" style={{ marginTop: '8px' }}>Incorrect password</div>}
                                    {isDszDomain && <div style={{ marginTop: '8px' }}><a style={{ color: '#942FFB' }} href={DszUrl + '/customer/account/forgotpassword/'}>Forgot Password?</a></div>}
                                  </div>
                                </AvGroup>
                                {isNeedRecaptchaCheck && (
                                  <>
                                    <ReCAPTCHA
                                      sitekey={recaptchaSiteKey}
                                      onChange={onChange}
                                      ref={captchaRef}
                                    />
                                    {recaptchaMessage && <span className="text-danger">{recaptchaMessage}</span>}
                                  </>)}
                                <div className="pt-lg-4">
                                  <Button
                                    size="lg"
                                    className="btn-block text-uppercase font-weight-bold font-size-sm"
                                    color={
                                      showFeatureItems5
                                        ? 'glowing-purple'
                                        : 'primaryOld'
                                    }
                                    disabled={loading}>
                                    {loading && (
                                      <span
                                        className="btn-wrapper--icon spinner-border spinner-border-sm mb-1 mr-2"
                                        role="status"
                                        aria-hidden="true"></span>
                                    )}
                                    Login
                                  </Button>
                                </div>
                                {errorMsg && (
                                  <div className="form-group mt-3">
                                    <Alert
                                      className="alerts-alternate"
                                      color="danger">
                                      <div className="d-flex align-items-center align-content-start">
                                        <span className="font-size-lg d-block d-40 mr-3 text-center bg-danger text-white rounded-sm">
                                          <FontAwesomeIcon
                                            icon={['far', 'keyboard']}
                                          />
                                        </span>
                                        <span>
                                          <strong className="d-block">
                                            Login Error!
                                          </strong>{' '}
                                          {errorMsg}
                                        </span>
                                      </div>
                                    </Alert>
                                  </div>
                                )}
                              </div>
                              <div className="text-center pt-4 ">
                                Don’t have a Dropshipzone Retailer account?{' '}
                                <a style={{ color: '#942FFB' }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://www.dropshipzone.com.au/customer/account/create/">
                                  {showFeatureItems5
                                    ? 'Get Started'
                                    : 'Create an Account for Dropshipzone.com.au'}
                                </a>
                              </div>
                            </div>
                          </div>
                        </AvForm>
                      </Col>
                      <Col
                        lg="6"
                        xs={{ order: 1 }}
                        md={{ order: 1 }}
                        className="d-flex d-lg-flex align-items-center">
                        <div className="w-100 pb-4">
                          <img
                            alt="..."
                            className="w-90 mx-auto d-block img-fluid"
                            src={
                              showFeatureItems5
                                ? illustrationPurple
                                : illustration1
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
